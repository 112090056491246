import Head from '../../components/Head/Head';
import Footer from '../../components/Footer/Footer';
import noisea_bg from '../../static/img/bg/noise_bg.png';
import { getLanguage, getText } from '../../config/language';
import './AboutUs.scss';
export default function AboutUs() {
	function getContent() {
		if (getLanguage().language === 'zh')
			return (
				<>
					<h2>
						<strong>我们的背景</strong>
					</h2>
					<p>
						<span>
							由Total Media Agency公司投资支持，Total Media
							Agency（TMA）是一家位于日本东京的知名成人视频制作公司，专注于为成人行业提供高质量的作品。这家机构与众多顶级AV偶像建立了紧密的合作关系，目前已成功签约180名出色的AV偶像。
						</span>
					</p>
					<p>
						<span>
							TMA在业界享有盛誉，旨在为观众带来最佳的观看体验，其制作的作品涵盖了各种类型和风格，以满足不同观众的需求。为了保持与市场的竞争力和创新，TMA不仅在招募新人，还重视与其他行业领导者的合作，以及对新兴技术和潮流的关注。除了生产高质量的成人视频，TMA还致力于保护AV偶像的权益，为他们提供良好的工作环境和待遇，以确保持续吸引优秀人才。这样的做法使TMA得以在成人娱乐行业中保持领先地位。
						</span>
					</p>
					<h2>
						<strong>主要成员</strong>
					</h2>
					<p>
						<strong>荒木 俊和（CEO）</strong>
					</p>
					<p>
						<span>
							2000年加入株式会社ソフトオンデマンド，作为爱知地区的CD/DVD销售负责人，连续3年取得销售第一的成绩。2005年参与TENGA的创立，担任执行董事，负责公司运营全面以及各种活动的宣传和策划。2016年任有限公司ベンハー的代表取締役。
						</span>
					</p>
					<p>
						<strong>梅本 竜二（CMO）</strong>
					</p>
					<p>
						<span>
							2002年加入ソフト・オン・デマンド株式会社，负责首都圈地区的CD/DVD销售、成人用品路线销售以及签名会、拍摄会等活动的策划与实施。后来，作为大阪地区和西日本地区经理，主要从事经理的管理和培养工作。2022年6月作为核心成员加入有限会社ベンハー。
						</span>
					</p>
					<p>
						<strong>黒木 健太（COO）</strong>
					</p>
					<p>
						<span>
							在音乐制作公司マッシュウエスト负责发掘和培养艺人，以及宣传推广工作。参与了两家演艺制作公司的创立和运营。迄今为止，已培养出了篠原もえ、南梨央奈、吉岡ひより等艺人。任株式会社ENLOOP的代表取締役社长。
						</span>
					</p>
					<p>
						<strong> </strong>
					</p>
					<p>
						<strong>马可·贝蒂奥洛（CTO）：</strong>
					</p>
					<p>
						<span>
							马可热衷于技术，拥有丰富的软件设计和开发经验。他在软件开发和工程领域有20多年的经验，涉及多个行业领域，包括咨询公司、保险、时尚和音乐。
						</span>
					</p>
					<p>
						<span>
							马可于2015年加入Tes，并在成为工程副总裁之前担任该公司的多个职位。他直接向首席执行官汇报，负责监督工程团队的有效执行路线图，以及他们的保留和成长。他的远程优先团队分布在世界各地，包括超过60名软件工程师。马可是高级领导团队的成员，参与并购活动、总体工程预算、战略和成果。
						</span>
					</p>
					<p>
						<span>
							技术栈：NodeJS、TypeScript、React、Redux、SASS、Docker、MongoDB、Redis、PostgreSQL、RabbitMQ、Nginx、Kibana、NewRelic、DataDog、Kubernetes、Solr、ElasticSearch
						</span>
					</p>
				</>
			);

		if (getLanguage().language === 'ja')
			return (
				<>
					<h2>私たちの背景</h2>
					<p>
						トータルメディアエージェンシーがサポートするトータルメディアエージェンシー（TMA）は、東京に拠点を置く有名なアダルトビデオ制作会社で、アダルト業界向けに高品質な作品を提供することに注力しています。
					</p>
					<p>
						このエージェンシーは、数多くのトップAVアイドルと緊密なパートナーシップを築き、180人の優れたAVアイドルと契約を結ぶことに成功しています。TMAは、さまざまなニーズに応えるため、幅広いジャンルとスタイルで観客に最高の視聴体験を提供することを目指し、業界内で高い評価を得ています。
					</p>
					<p>
						市場での競争力と革新性を維持するため、TMAは新しい才能を採用するだけでなく、他の業界リーダーとのコラボレーションを重視し、新しい技術やトレンドに目を光らせています。TMAは、高品質のアダルトビデオを制作するだけでなく、AVアイドルの権利と利益を保護し、良い労働条件と待遇を提供し、優秀な人材を継続的に確保することにも取り組んでいます。このようなアプローチにより、TMAはアダルトエンタテインメント業界におけるリーディングポジションを維持しているのです。
					</p>
					<h2>主要メンバー</h2>
					<p>
						<strong>荒木 俊和（CEO）</strong>
					</p>
					<p>
						2000年、株式会社ソフト・オン・デマンド入社、愛知エリアのCD/DVD販売担当として、3年連続売上1位を達成。2005年、株式会社TENGAの設立に参画し、専務取締役として会社運営全般、各種活動の推進・企画を担当。2016年株式会社ベンハー代表取締役に就任。
					</p>
					<p> </p>
					<p>
						<strong>梅本 竜二（CMO）</strong>
					</p>
					<p>
						2002年ソフト・オン・デマンド株式会社入社、首都圏でのCD/DVD販売、アダルトグッズ販売、サイン会・撮影会などのイベント企画・実施などを担当する。その後、大阪・西日本エリアのマネージャーとして、主にマネージャーの管理・育成に携わる。2022年6月、株式会社ベンハーにコアメンバーとして参画。
					</p>
					<p> </p>
					<p>
						<strong>黒木 健太（COO）</strong>
					</p>
					<p>
						アーティストの発掘・育成、音楽プロダクション「マッシュウエスト」の宣伝・推進を担当。芸能プロダクション2社の設立・運営に参画。これまでに篠原萌、南里央奈、吉岡ひよりなどのアーティストを育成。株式会社ENLOOPの代表取締役社長に就任。
					</p>
					<p> </p>
					<p>
						<strong>マルコ・ベッティオール（CTO）：</strong>
					</p>
					<p>
						マルコはテクノロジーに情熱を持っており、ソフトウェアの設計と開発の経験が豊富である。コンサルティング会社、保険、ファッション、音楽など、複数の業界にわたるソフトウェア開発・エンジニアリングの経験を20年以上持っています。
					</p>
					<p>
						マルコは2015年にテスに入社し、社内でいくつかのポジションを経験した後、エンジニアリング担当副社長に就任しました。彼はCEOに直接報告し、エンジニアリングチームがロードマップを効果的に実行すること、そして彼らの維持と成長を監督する責任を負っています。彼の率いるリモートファーストのチームは世界中にあり、60人以上のソフトウェアエンジニアを擁しています。マルコはシニア・リーダーシップ・チームのメンバーとして、M&amp;A、エンジニアリング全体の予算、戦略、結果に関与しています。
					</p>
					<p>
						テクノロジースタック：NodeJS, TypeScript, React, Redux, SASS,
						Docker, MongoDB, Redis, PostgreSQL, RabbitMQ, Nginx, Kibana,
						NewRelic, DataDog, Kubernetes, Solr, ElasticSearch
					</p>
				</>
			);

		return (
			<>
				<h2>Our Background</h2>
				<p>
					Supported by Total Media Agency, Total Media Agency (TMA) is a
					well-known adult video production company based in Tokyo, Japan,
					focusing on providing high-quality work for the adult industry. The
					agency has established close partnerships with numerous top AV idols
					and has successfully signed 180 outstanding AV idols. TMA enjoys a
					strong reputation in the industry, aiming to bring the best viewing
					experience to audiences with a wide variety of genres and styles to
					cater to different needs. To maintain competitiveness and innovation
					in the market, TMA not only recruits new talent but also values
					collaborations with other industry leaders and keeps an eye on
					emerging technologies and trends. Besides producing high-quality adult
					videos, TMA is also committed to protecting the rights and interests
					of AV idols, providing them with good working conditions and
					treatment, and ensuring a continuous influx of excellent talent. This
					approach enables TMA to maintain a leading position in the adult
					entertainment industry.
				</p>
				<h2>Key Members</h2>
				<p>
					<strong>Shunwa Araki (CEO)</strong>
				</p>
				<p>
					Joined SOFT ON DEMAND Co., Ltd. in 2000 as the person in charge of
					CD/DVD sales in the Aichi area, achieving first place in sales for
					three consecutive years. Participated in the founding of TENGA in
					2005, served as executive director, responsible for overall company
					operations as well as promotion and planning of various activities.
					Appointed as representative director of Benharr Co., Ltd. in 2016.
				</p>
				<p> </p>
				<p>
					<strong>Ryuji Umemoto (CMO)</strong>
				</p>
				<p>
					Joined Soft On Demand Co., Ltd. in 2002, responsible for CD/DVD sales
					in the metropolitan area, adult product sales, and planning and
					implementation of events such as autograph sessions and photo
					sessions. Later, as the manager of the Osaka and West Japan areas,
					mainly engaged in the management and training of managers. Joined
					Benharr Co., Ltd. as a core member in June 2022.
				</p>
				<p> </p>
				<p>
					<strong>Kenta Kuroki (COO)</strong>
				</p>
				<p>
					In charge of discovering and cultivating artists as well as promoting
					and promoting music production company Mash West. Participated in the
					founding and operation of two entertainment production companies. To
					date, he has cultivated artists such as Moe Shinohara, Riona Minami,
					and Hiyori Yoshioka. Appointed as representative director and
					president of ENLOOP Co., Ltd.
				</p>
				<p> </p>
				<p>
					<strong>Marco Bettiol (CTO):</strong>
				</p>
				<p>
					Marco is passionate about technology and has extensive software design
					and development experience. He has over 20 years of experience in
					software development and engineering, spanning multiple industry
					sectors, including consulting firms, insurance, fashion, and music.
				</p>
				<p>
					Marco joined Tes in 2015 and held several positions within the company
					before becoming vice president of engineering. He reports directly to
					the CEO and is responsible for overseeing the engineering team's
					effective execution of roadmaps, as well as their retention and
					growth. His remote-first team is located around the world, including
					over 60 software engineers. Marco is a member of the senior leadership
					team, involved in mergers and acquisitions, overall engineering
					budgets, strategy, and results.
				</p>
				<p>
					Technology stack: NodeJS, TypeScript, React, Redux, SASS, Docker,
					MongoDB, Redis, PostgreSQL, RabbitMQ, Nginx, Kibana, NewRelic,
					DataDog, Kubernetes, Solr, ElasticSearch
				</p>
			</>
		);
	}

	return (
		<>
			<Head />
			<main className="main-content fix aboutuspanel">
				<div
					className="noise-bg"
					data-background={noisea_bg}
					style={{
						backgroundImage: `url(${noisea_bg})`,
					}}
				></div>

				<section className="banner-area">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="banner-content ta-animated-headline">
									<h2
										className="title ah-headline wow fadeInUp"
										data-wow-delay=".2s"
									>
										{getText('aboutUs').text}
									</h2>
									<h2
										className="title d-none wow fadeInUp"
										data-wow-delay=".2s"
									>
										{getText('aboutUs').text}
									</h2>
								</div>
							</div>
						</div>

					<div className="aboutus-content">
                    {getContent()}
                    </div>
					</div>
				</section>
			</main>
			<Footer />
		</>
	);
}
