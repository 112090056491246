import {useState} from 'react';
import {message} from 'antd';


const language = localStorage.getItem('language') || 'en';

function getValueForLanguage() {


    if (language === 'zh')
        return {

            copyLink:"复制链接",
            copySuccess:"复制成功",
        };

    if (language === 'ja')
        return {

            copyLink:"リンクのコピー",
            copySuccess:"コピー成功",


        };


    return {
        copyLink:"Copy Link",
        copySuccess:"Copy Success",
    };

}


function ContentRight(prop) {
    let [activeListDetail, updateActiveList] = useState(0);
    let title = prop.title;
    // //滑动滚动条
    // const handleScroll = () => {
    //   let h2Arr = document.getElementsByTagName("h2");
    //   for (let i = 0; i < h2Arr.length; i++) {
    //     if (h2Arr[i].offsetTop <= window.pageYOffset) {
    //       updateActiveList(i);
    //     }
    //   }
    // };
    // window.addEventListener("scroll", handleScroll, true);
    //点击关键节点
    const scrollTob = (item, index) => {
        updateActiveList(index);
        const scrollTop = document.getElementsByTagName('h2')[index].offsetTop;
        window.scrollTo({
            top: scrollTop,
            behavior: 'smooth',
        });
    };
    return (
        <div className={'list'}>
            <div
                onClick={()=>{
                    let textarea = document.createElement("textarea");
                    textarea.value = window.location.href;
                    message.info(getValueForLanguage().copySuccess);
                    textarea.style.position = "fixed";  // 确保textarea在可见区域之外
                    document.body.appendChild(textarea);
                    textarea.select();
                    document.execCommand("copy");
                    document.body.removeChild(textarea);
                }}
                className={'list-detail'}
                style={{padding:0,margin:"10px 0 30px 0"}}
            >
                <svg viewBox="0 0 16 16"
                     style={{verticalAlign:"middle",width:"20px",height:"20px",marginRight:"5px"}}
                >
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12.525 3.474a2.4 2.4 0 00-3.394 0L7.717 4.89a.6.6 0 01-.848-.849l1.414-1.414a3.6 3.6 0 015.091 5.091L11.96 9.131a.6.6 0 01-.849-.848l1.414-1.414a2.4 2.4 0 000-3.395zm-1.272 1.273a.6.6 0 010 .849l-5.657 5.657a.6.6 0 01-.849-.849l5.657-5.657a.6.6 0 01.849 0zM4.889 6.87a.6.6 0 010 .848L3.474 9.131a2.4 2.4 0 103.395 3.394l1.414-1.414a.6.6 0 01.848.849l-1.414 1.414a3.6 3.6 0 11-5.091-5.091L4.04 6.869a.6.6 0 01.849 0z"
                          fill="currentColor"></path>
                </svg>
                {getValueForLanguage().copyLink}
            </div>
            {title.map((item, index) => (
                <div
                    className={[
                        'list-detail',
                        index === activeListDetail ? 'active-list-detail' : '',
                    ].join(' ')}
                    onClick={() => scrollTob(item, index)}
                    key={item}
                >
                    {item}
                </div>
            ))}
        </div>
    );
}

export default ContentRight;
