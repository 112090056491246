import { message } from 'antd';
import config from '../../config/config';
import { getText } from '../../config/language';

export default function Footer(props) {
	return (
		<>
			<footer>
				<div className="footer-area">
					<div className="container">
						{props.friendsImgs && props.friendsImgs.length > 0 && (
							<div className="brand-area">
								<div className="brand-wrap">
									<h6 className="title">
										{/* Trusted by <span>10,000+ marketing</span> teams */}
										{getText('partner').text}
									</h6>
									<div className="row brand-active">
										{props.friendsImgs.map((item, index) => {
											console.log(item);
											return (
												<div className="col" key={index}>
													<div className="brand-item">
														<img
															src={`${config.baseUrl}/attributes/img?key=${item}`}
															style={{ height: '48px' }}
															alt=""
														/>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						)}
						<div className="footer-top">
							<div className="row">
								<div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<div className="footer-widget">
										<h4 className="fw-title">company</h4>
										<div className="footer-link">
											<ul className="list-wrap">
												{/* <li>
													<a href="/contact">Affiliate program</a>
												</li>
												<li>
													<a href="login.html">Account</a>
												</li>
												<li>
													<a href="/contact">Invite a friend</a>
												</li>
												<li>
													<a href="/contact">Privacy policy</a>
												</li>
												<li>
													<a href="/contact">Terms of use</a>
												</li> */}
												<li>
													<a
														href={"/aboutus"}
													>
														About Us
													</a>
												</li>
												<li>
													<a href="/contact">Contact Us</a>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
									<div className="footer-widget">
										<h4 className="fw-title">Need help?</h4>
										<div className="footer-contact">
											<a href="mailto:info@hotluuu.io" className="email">
												info@hotluuu.io
											</a>
										</div>
									</div>
								</div>

								<div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									{/* <div className="footer-widget">
										<h4 className="fw-title">Use Case</h4>
										<div className="footer-link">
											<ul className="list-wrap">
												<li>
													<a href="/contact">For teams</a>
												</li>
												<li>
													<a href="/contact">For blog writer</a>
												</li>
												<li>
													<a href="/contact">For social media</a>
												</li>
												<li>
													<a href="/contact">Report & Outage</a>
												</li>
												<li>
													<a href="/contact">Email Marketing</a>
												</li>
											</ul>
										</div>
									</div> */}
								</div>
								{/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<div className="footer-widget">
										<h4 className="fw-title	">Product</h4>
										<div className="footer-link">
											<ul className="list-wrap">
												<li>
													<a href="/">Hotluuu.io</a>
												</li>
												<li>
													<a href="work.html">Our work</a>
												</li>
												<li>
													<a href="about.html">About us</a>
												</li>
												<li>
													<a href="help.html">Support</a>
												</li>
												<li>
													<a href="/contact">Contact us</a>
												</li>
											</ul>
										</div>
									</div>
								</div> */}
								<div className="col-xl-5 col-lg-6 col-md-6">
									<div className="footer-widget">
										<div className="footer-newsletter">
											<h6 className="title">
												{getText('moreInformation').text}
											</h6>
											<p>{getText('getMoreInformation').text}</p>
											<a href="/contact" className="btn btn-two">
												{getText('contact').text}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="footer-bottom">
							<div
								className="row align-items-center"
								style={{ alignItems: 'flex-start' }}
							>
								<div className="col-lg-8">
									<div className="copyright-text">
										<p>
											Copyright © 2022 HotLuuu All rights By{' '}
											<a href="https://hotluuu.io">Reserved</a>.
										</p>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="footer-menu">
										<ul className="list-wrap">
											<li>
												<a href="/">
													<svg
														t="1687711328133"
														className="icon"
														viewBox="0 0 1280 1024"
														version="1.1"
														xmlns="http://www.w3.org/2000/svg"
														p-id="1455"
														width="29"
														height="29"
														style={{ height: '16px' }}
													>
														<path
															d="M361 148.524C161.626 148.524 0 311.266 0 512s161.638 363.476 361 363.476S722 712.746 722 512 560.382 148.524 361 148.524z m576.5 21.292c-99.69 0-180.49 153.238-180.49 342.19s80.812 342.2 180.502 342.2 180.502-153.238 180.502-342.2H1118c0-189.006-80.8-342.19-180.496-342.19z m279.012 35.642c-35.052 0-63.47 137.256-63.47 306.548s28.4 306.548 63.47 306.548S1280 681.262 1280 512c0-169.298-28.43-306.542-63.484-306.542z"
															p-id="1456"
															fill="#ffffff"
														></path>
													</svg>
												</a>
											</li>
											<li>
												<a href="/">
													<img
														style={{ height: '16px' }}
														className="jss268"
														src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAfCAYAAACVgY94AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHpSURBVHgB3ViNeYIwED2cgBFwAukGdII6gp2gdALtBnYC7QRtJ5AN7AZhA9jgekcSv9SGcKAV6/u+R/i5Cy+X5BIAuBUgYkzMiRui4rKHL/vsie/EBTGBc8CIWhJ36Edi7BLDlJjZa+eZD/uTxJpWVxhG13OpzYYY9xG3xMtj5dMSecQlVCgYB9Moikr3xsRjtITxEJ54qAf42MhcTccRHDN64NNwGIM47tg7xj2NxYJP3AheQ/Qs5vakiSDqHMTRk+eiv0UNekbXNoKs+FrEMVhLE0Ur8AHkKImFKaWoB/gcupm7WLpkzd0a6Hol8Fujs4yhXn9F77MOKcqQ+5pJ97cBn02LzwJlSKXGVUs3dCX3LOAniWLOYzCFbnwNfKYG+lkkLHAmMQw8Gzr7JX6NQJEhtm8ss3Y3ePTdNHVJem4mHQuMne9FqLf/bah8DUO99ZdARXwEOUriM+i8xhF4gnD3W58XU8bGJwMh+gq8OCZw5fgXAkuBXQ391tFz1VeywE+B4SvxDnTaKGA4CtCTbEr8ENi/NUfUy53qmvLGLjaco94I7FB/hFdH6UWZdLI2ttYvF7zr18bE5rQtdiOBgUDZR9mP3Y+vkpBQ8f+YQP2tdfdqvCNUmQoU4uk/fVB3sx0OXK5CEfsGXF9Kwb/S8xsAAAAASUVORK5CYII="
														alt=""
													/>
												</a>
											</li>
											<li>
												<a href="/">
													<img
														style={{ height: '16px' }}
														className="jss268"
														src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGtSURBVHgB7ZfrUcMwEITXVBAqQFRASkgJoQOXABUQKoBUgDugBEIFpAO5A6eD5TQSQx5SbFmy/cffzI1jKTqv7/Q4AzMzM9NCshT7Emtoadx9iSkRAUpM8zqmX8X4NY4XxpBAR3EXIt24ZZvzTzeot8gIcX80/J8ChrKL8zf0gHbOpaAZSjtteo95QSS0CyCF8ppz5RkQJZKnqYrlo835KjSQHVcb01BtztWVwZp2fi0wUAR9/m7QHSVmUqBpI7qmf0vYIyPF8Y2LToN46qPfCj0phIu28waTypSHJHAQfbfnjb4UZ01RBN7n+gR+YxpqX6NPYIVp8AbmQqDMg4Ncdhifna8xtM28Ylz2Epja1+EVKH/eYdwobkMdRajDHTs/Ykm1YQdqCch9qDN4kriQP2N4tkhBIrnhcGjkwJ27mvlZIhe0lU7FfGwwBOatmVaUGt4xBLQFrWYaFXJC+53yxPRvDcMGkZzXg0oua7E72P1vhTyllzk+H90B0B/m31Ia5zPfZk87zyqmLYRswgq0iJWL2auU2ANs2hVOj7+DM1NwmpJpn5zKmZmZ7vwCfMlOEi/38OgAAAAASUVORK5CYII="
														alt=""
													/>
												</a>
											</li>
											<li>
												<a href="/">
													<img
														style={{ height: '16px' }}
														className="jss268"
														src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJvSURBVHgB7ZjhVRsxDIB1ef3fbIA7Admg1wnIBkk3YIPABIQJUiZoO8FdJyBMcOkEhAmEhH0PR3cmkuESeI/vPT1HPvusyLZkH8Anr6NIPUDEMRVzklOSCck4PHJgZxOVa5J/RVH8gRzIMEdS4fA0JAurcTOSezwsDYnTGncsmpSRRTutVNzC8zo7BjWtyx+ychRKXgvHNI4pyVFTWVkE7zUwPBuSa/COmEF/NOh6kQyc4/A08RpDHylSm3FnJnmKz2B47sgzm1YJvzeJtmWsfIH8tbcluQEfeNdBd+CD+pkY6KSnf2pct6MF91tZyqmQYDfgL7hPkKsX3n3xGgN53ZRggAdEGyv5AgvTF7zFMk48XxrGyDbwomdgjgC3ol2FIiugn9YGdWQZeN8z6GJPn6loX+KABq56PLcP/lNj0U9zENkZawQ6/gpdc0xi485FXQ1GtAZu2x/BK07Z77vQ/4MRrYFxrrYEdtl2C0a0Bn7LHES2NWctrYGT9gflUR60Vva7EfopWEEdlegzUezIRvRxqCNrF3MMK1uFvMiHg5+QPpHwc3k6nkEOqKfq6cte4TRWoc8Uv0nmiXZaOoHacouzXRPBnOY6BvIUW3Yln0yutI3x+TLmQM9DrGjXYMx58Mg81SB4bZFhHLPjMD5RrzNe4khWwZs1yV2o/wo+JJWQT8dAc/qJ4MA7DfJWrGOFpzjvI84wbCmE1XEFG9heeN4DHWeNQuq6hvfBpaxod/ES0lnhUFzGd+cO6KN9g8fhF2jAw328jFmCFfR3jgqHg9PrChV37GKPoQ584OWSY94J5MPxljfk06eSsDk/Po+8IKrUoK+N5AAAAABJRU5ErkJggg=="
														alt=""
													/>
												</a>
											</li>
											<li>
												<a href="/">
													<img
														style={{ height: '16px' }}
														className="jss268"
														src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADzSURBVHgB7ZZtDcIwEIbfEgRUwhyAhSkACzhAAg5gDrAwB3PAHKwS5qBck/6BtbllX22We5LLkmu3PGmvtypr7RPAHXnwUiRkkREHZIYIcRyxLoaipuh9aJ93zwtFgY2EDMVNKdXEJtBZ0lsJGYqSZAwmsEYNTZZxLC3UzJFxLL1ldSjp6+WK35o5YQOhNpL/IFDAIVbvQ7Q6BUbKOKRTc+zq19FiWMR9YJ7LvQN5d+r0IGun88AM6P0u9NGUW1aEkkmEaCHOsbFUK6RjA6mEoiu09CW//L8D+U7dYSTSGDlEiEOEOESIQ4Q4RIjDCVXIh+oLrZKFZQNESJgAAAAASUVORK5CYII="
														alt=""
													/>
												</a>
											</li>
											<li>
												<a href="/">
													<img
														style={{ height: '16px' }}
														className="jss268"
														src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ4SURBVHgBzZmNcdswDIUhXwZwN1AnqDeIuoG7gTeoNlA6QdIJ7E5QdwKpE9idQNkg6QQvQEgljKIfgqLkfHc42TrSfAZIALITCgRAxpcN27W9rq0Jj2z31v6xVUmSVDQ3LGrNVrA9QE/NtmdLKTZW2C3isY8mlD/oO8I8NkbNtqMpIK7X+rglLTAhLbEcJ1lTI/CE5Sm7tKw6xInLN7Q8GcbCzQN2uDy5qylxxKV8ETendFkkyX/mxC7XNyEu6PLiBDksRfPm2YPWezV9LD6JFxsPFjQ/ErIfsjDbN4/xr3sRJqvPgVSg3zCNhbvexmeujL2yk1OKS8X2h+3QbPYWKY0jxSK74hcZxUGE/GI7uq0VTIXY8r2DMzYjPzZkQzCFkm2LjlIF02hIVUpb90vPzz6GljXZWzfoqZ8iyIqo0dFW+S+DmuDfSnVu+I7FCzu2swGA3wF5WdPn29RsOUa6DRGO12xwQL93cyjwEZiNCGt323cj4/dQ4BPiJrTSSKxbi21b829oBOj2/ENIki5hwlS27uce4tbQcYqRZsSDO/IAZp9qOEot/kvTOLaS8BDaRvgsAs80jf+Ksdeko1rZsvRI4bzMhakc6cBYjQfvRVvTbv2kcJ4FsjBp2STFyCl9d2Cs8JT8qdzJ2tPlIumn6LgvuXHtrLGFjrT9De8QxlAerZuFoEvQ+3f+hPHiHD9xCJrkXKNvH0NZJ2diR0MgPNQxGKzjrsgSy3Pq0rLq0ShPXVMTuIaK7StpwTLh9gvrgEjJczXiIxkjpxjAPGMcEA+JjP/vgQFCa+gZfNDqI6FAYB4FxL6QqbFi7t8QYnLQpJ07h/4N8QR0Gs6t4QL5/QAAAABJRU5ErkJggg=="
														alt=""
													/>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}
