import Footer from '../../components/Footer/Footer';
import Head from '../../components/Head/Head';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import config from '../../config/config';
import './WhitePaper.scss';
import openWhitePage from '../../static/img/images/open-white-page.png';
import closeDrawer from '../../static/img/images/close-drawer.png';
import { Drawer,FloatButton } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ContentRight from './Component/ContentRight';
import noisea_bg from '../../static/img/bg/noise_bg.png';
import main_shape from '../../static/img/images/main_shape.png';
import {MenuUnfoldOutlined} from '@ant-design/icons';

let language = localStorage.getItem('language') || 'en';

function getQueryString(name) {
	let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
	let r = window.location.search.substr(1).match(reg);
	if (r != null) {
		return unescape(r[2]);
	}
	return null;
}
function getValueForLanguage() {
	if (language === 'zh')
		return {
			previous: '上一章',
			next: '下一章',
		};

	if (language === 'ja')
		return {
			previous: '前の章',
			next: '次の章',
		};
	return {
		previous: 'Previous',
		next: 'Next',
	};
}

export default function () {
	let pathname = useLocation().pathname;
	const [open, setOpen] = useState(false);
	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	const [menuList, setMenuList] = useState([]);
	const [content, setContent] = useState('');
	const [index, setIndex] = useState(parseInt(getQueryString('id')) || 0);
	const [title, setTitle] = useState([]);

	useEffect(() => {
		fetch(
			`${config.baseUrl}/attributes/getWhitePaper/?language=${language}&pageSize=9999&pageNum=1`,
			{ mode: 'cors' }
		)
			.then((res) => res.json())
			.then((res) => {
				setMenuList(
					res?.map((v) => {
						return { title: v.title, path: v.id };
					})
				);
				if (res?.length && !index) {
					setIndex(res[0].id);
				}
			});
	}, []);

	useEffect(() => {
		if (index) {
			fetch(`${config.baseUrl}/attributes/getWhitePaperDetail?id=${index}`, {
				mode: 'cors',
			})
				.then((res) => res.json())
				.then((res) => {
					setContent(res?.content || '');
				});
		}
	}, [index]);

	useEffect(() => {
		let div = document.createElement('div');
		div.innerHTML = content;
		let tempTtitles = div.getElementsByTagName('h2');
		if (tempTtitles?.length) {
			let titles1 = [];
			for (let i = 0; i < tempTtitles.length; i++) {
				titles1.push(tempTtitles[i].innerText);
			}
			setTitle(titles1);
		} else {
			setTitle([]);
		}
	}, [content]);

	const [previous, setPrevious] = useState({});
	const [next, setNext] = useState({});

	useEffect(() => {
		if (!menuList?.length) {
			setNext({});
			setPrevious({});
			return;
		}

		menuList.forEach((item, i) => {
			if (item.path === index) {
				if (i === 0) {
					setNext(menuList[1]);
					setPrevious({});
				} else if (i === menuList.length - 1) {
					setNext({});
					setPrevious(menuList[i - 1]);
				} else {
					setNext(menuList[i + 1]);
					setPrevious(menuList[i - 1]);
				}
			}
		});
	}, [menuList]);

	return (
		<>
			<Head current={'whitepage'} />
			<main  className="main-content whitepaper">
				<div
					className="noise-bg"
					data-background={noisea_bg}
					style={{
						backgroundImage: `url(${noisea_bg})`,
					}}
				></div>

				<section className="banner-area">
					
						<div className="row">
							<div className="col-lg-12">
								<div className={'white-paper-container container'}>

								<FloatButton onClick={() => showDrawer()}  className={'openDrawer'} icon={<MenuUnfoldOutlined/>} />

{/* 
									<div onClick={() => showDrawer()} style={{
										backgroundColor:"1b004a",
										position:"fixed",
										bottom:"20px",
										right:"10px"
									}} >
										
									</div> */}
									<div className={'white-paper-menu'}>
										<div className={'white-paper-menu-detail'}>
											{menuList.map((item, i) => {
												return (
													<div
														key={item.path}
														className={[
															'white-paper-menu-name',
															index === item.path
																? 'active-white-paper-menu-name'
																: '',
														].join(' ')}
														onClick={() => {
															window.location.href = `${pathname}?id=${item.path}`;
														}}
													>
														{item.title}
													</div>
												);
											})}
										</div>
									</div>
									<div className={'white-paper-main-content'}>
										<div className={'content'}>
											<div
												className={'left'}
												id={'content-left'}
												dangerouslySetInnerHTML={{ __html: content }}
											></div>
											<div className={'right'}>
												<ContentRight title={title} />
											</div>
										</div>

										<div className="change-page">
											<a
												href={`${pathname}?id=${previous.path}`}
												className={`previous ${next.title?'':'no-next'}`}
												style={{
													display: previous.title ? 'flex' : 'none',
												
												}}
											>
												<LeftOutlined style={{ color: '#FFF' }} />
												<div className={'change-btn'}>
													<div className={'text'}>
														{getValueForLanguage().previous}
													</div>
													<div className={'title'}>{previous.title}</div>
												</div>
											</a>
											<div style={{ margin: '10px 10px' }}></div>
											<a
												href={`${pathname}?id=${next.path}`}
												className={`next ${previous.title?'':'no-previous'}`}
												style={{
													display: next.title ? 'flex' : 'none',
											
												}}
											>
												<RightOutlined style={{ color: '#FFF' }} />
												<div className={'change-btn'}>
													<div className={'text'}>
														{getValueForLanguage().next}
													</div>
													<div className={'title'}>{next.title}</div>
												</div>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
				
				</section>
			
			</main>

			<Footer />
            <Drawer
					className="white-page-menu-drawer"
					placement="left"
					onClose={onClose}
					open={open}
				>
					<img src={closeDrawer} alt="" onClick={onClose}></img>
					{menuList.map((item, i) => (
						<div
							key={i}
							className={[
								'white-paper-menu-name',
								index === item.path ? 'active-white-paper-menu-name' : '',
							].join(' ')}
							onClick={() => {
								setIndex(item.path);
								onClose();
							}}
						>
							{item.title}
						</div>
					))}
				</Drawer>
		</>
	);
}
