import './Head.scss';
import logo_white from "../../static/img/logo/logo_white.png"
import { useEffect } from 'react';
import {
	getLanguage,
	getText,
	languageIcon,
	setLanguage,
} from '../../config/language';

let loaded = false;

export default function Head(props) {
	function createScript(src) {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script');
			script.src = src;
			script.async = true;
			script.onload = () => {
				resolve();
			};
			script.onerror = (error) => {
				reject(error);
			};
			document.body.appendChild(script);
		});
	}

	async function loadScriptHandle() {
		await createScript('./js/vendor/jquery-3.6.0.min.js');
		await createScript('./js/bootstrap.min.js');
		await createScript('./js/imagesloaded.pkgd.min.js');
		await createScript('./js/jquery.magnific-popup.min.js');
		await createScript('./js/jquery.odometer.min.js');
		await createScript('./js/jquery.appear.js');
		await createScript('./js/gsap.js');
		await createScript('./js/ScrollTrigger.js');
		await createScript('./js/ScrollToPlugin.min.js');
		await createScript('./js/SplitText.js');
		await createScript('./js/gsap-animation.js');
		await createScript('./js/select2.min.js');
		await createScript('./js/slick.min.js');
		await createScript('./js/animatedheadline.min.js');
		await createScript('./js/aos.js');
		await createScript('./js/ajax-form.js');
		await createScript('./js/wow.min.js');
		await createScript('./js/main.js');
	}

	useEffect(() => {
		if (!props.loading) {
			if (!loaded) {
				loadScriptHandle();
				loaded = true;
			}
		}
	}, [props.loading]);

	return (
		<>
			<header>
				<div id="sticky-header" className="menu-area transparent-header">
					<div className="container custom-container">
						<div className="row">
							<div className="col-12">
								<div className="mobile-nav-toggler">
									<i className="fas fa-bars"></i>
								</div>
								<div className="menu-wrap">
									<nav className="menu-nav">
										<div className="logo">
											<a
												href="/"
												style={{ display: 'inline-flex', alignItems: 'center' }}
											>
												<img
													style={{ height: '40px',width:"auto" }}
													src={logo_white}
													alt="Logo"
												/>
												
											</a>
										</div>
										<div className="navbar-wrap main-menu d-none d-lg-flex">
											<ul className="navigation">
												<li className={props.current === '/' ? 'active' : ''}>
													<a href="/">{getText('home').text}</a>
												</li>
												<li
													className={props.current === 'news' ? 'active' : ''}
												>
													<a href="/news">{getText('news').text}</a>
												</li>
												<li
													className={props.current === 'market' ? 'active' : ''}
												>
													<a href="/market">market</a>
												</li>
												{/* <li
													className={
														props.current === 'whitepaper' ? 'active' : ''
													}
												>
													<a href="/whitepaper">{getText('whitePaper').text}</a>
												</li> */}
											</ul>
										</div>
										<div className="header-action d-none d-md-block">
											<ul className="list-wrap">
												<li className="header-lang">
													<div className="dropdown">
														<button
															className="dropdown-toggle"
															type="button"
															id="dropdownMenuButton1"
															data-bs-toggle="dropdown"
															aria-haspopup="true"
															aria-expanded="false"
														>
															<img src={getLanguage().icon} alt="" />
															{getLanguage().language.toUpperCase()}
														</button>
														<div
															className="dropdown-menu"
															aria-labelledby="dropdownMenuButton1"
														>
															<a
																onClick={() => setLanguage('en')}
																className="dropdown-item"
															>
																<img src={languageIcon.en} alt="" />
																EN
															</a>
															<a
																onClick={() => setLanguage('ja')}
																className="dropdown-item"
															>
																<img src={languageIcon.ja} alt="" />
																JA
															</a>
															<a
																onClick={() => setLanguage('zh')}
																className="dropdown-item"
															>
																<img src={languageIcon.zh} alt="" />
																ZH
															</a>
														</div>
													</div>
												</li>
												<li className="header-btn">
													<a
														style={{ width: '180px' }}
														href="/contact"
														className="btn"
													>
														{getText('contact').text}
													</a>
												</li>
											</ul>
										</div>
									</nav>
								</div>
								<div className="mobile-menu">
									<nav className="menu-box">
										<div className="close-btn">
											<i className="fas fa-times"></i>
										</div>
										<div className="nav-logo">
											<a
												href="index.html"
												style={{ display: 'inline-flex', alignItems: 'center' }}
											>
												<img
											
													src={logo_white}
													alt="Logo"
												/>
										
											</a>
										</div>

										<div className="menu-outer"></div>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												gap: '10px',
												marginTop: '20px',
											}}
										>
											<div
												onClick={() => {
													setLanguage('en');
												}}
												style={{
													textDecoration:
														getLanguage().language === 'en'
															? 'underline'
															: 'none',
												}}
											>
												En
											</div>
											<div
												onClick={() => {
													setLanguage('ja');
												}}
												style={{
													textDecoration:
														getLanguage().language === 'ja'
															? 'underline'
															: 'none',
												}}
											>
												Ja
											</div>
											<div
												onClick={() => {
													setLanguage('zh');
												}}
												style={{
													textDecoration:
														getLanguage().language === 'zh'
															? 'underline'
															: 'none',
												}}
											>
												Zh
											</div>
										</div>
									</nav>
								</div>
								<div className="menu-backdrop"></div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}
