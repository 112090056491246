import enIcon from '../static/img/flag/en.png';
import jaIcon from '../static/img/flag/ja.png';
import zhIcon from '../static/img/flag/zh.png';

const language = localStorage.getItem('language') || 'en';

export function getText(key) {
	let text = '';

	switch (key) {
		case 'home':
			if (language === 'en') text = 'Home';
			if (language === 'ja') text = 'ホームページ';
			if (language === 'zh') text = '首页';
			break;
		case 'news':
			if (language === 'en') text = 'News';
			if (language === 'ja') text = 'お知らせ・ニュース';
			if (language === 'zh') text = '新闻/动态';
			break;
		case 'whitePaper':
			if (language === 'en') text = 'White Paper';
			if (language === 'ja') text = 'ホワイトペーパー';
			if (language === 'zh') text = '白皮书';
			break;
		case 'contact':
			if (language === 'en') text = 'Contact';
			if (language === 'ja') text = 'お問い合わせ';
			if (language === 'zh') text = '联系我们';
			break;
		case 'projectIntroduce':
			if (language === 'en') text = 'Project Introduce';
			if (language === 'ja') text = 'プロジェクト紹介';
			if (language === 'zh') text = '项目介绍';
			break;
		case 'newsJsx':
			if (language === 'en')
				text = (
					<>
						Latest<span>News</span>
					</>
				);
			if (language === 'ja')
				text = (
					<>
						お知らせ・<span>ニュース</span>
					</>
				);
			if (language === 'zh')
				text = (
					<>
						查看最新的<span>新闻/动态</span>
					</>
				);
			break;
		case 'more':
			if (language === 'en') text = 'More';
			if (language === 'ja') text = 'もっと見る';
			if (language === 'zh') text = '查看更多';
			break;
		case 'detail':
			if (language === 'en') text = 'Detail';
			if (language === 'ja') text = '詳細';
			if (language === 'zh') text = '详情';
			break;
		case 'partner':
			if (language === 'en') text = 'Partner';
			if (language === 'ja') text = 'パートナー';
			if (language === 'zh') text = '合作伙伴';
			break;
		case 'moreInformation':
			if (language === 'zh') text = '更多资讯';
			if (language === 'ja') text = '詳細';
			if (language === 'en') text = 'More information';
			break;
		case 'getMoreInformation':
			if (language === 'zh') text = '联系我们获取更多相关资讯';
			if (language === 'ja') text = '詳細については、お問い合わせください';
			if (language === 'en') text = 'Contact us for more information';
			break;

		case 'contactUsJsx':
			if (language === 'zh')
				text = (
					<>
						有任何问题都可以<span>联系我们</span>
					</>
				);
			if (language === 'ja')
				text = (
					<>
						お問い合わせ<span>ください</span>
					</>
				);
			if (language === 'en')
				text = (
					<>
						Do you have question <span>contact us</span>
					</>
				);
			break;

		case 'getInTouchJsx':
			if (language === 'zh')
				text = (
					<>
						联系<span>我们</span>
					</>
				);
			if (language === 'ja') text = <>お問い合わせ</>;
			if (language === 'en')
				text = (
					<>
						Get in<span>Touch</span>
					</>
				);
			break;
		case 'address':
			if (language === 'zh') text = '地址';
			if (language === 'ja') text = '住所';
			if (language === 'en') text = 'Address';
			break;

		case 'email':
			if (language === 'zh') text = '邮箱';
			if (language === 'ja') text = 'メールアドレス';
			if (language === 'en') text = 'Email';
			break;

		case 'phone':
			if (language === 'zh') text = '电话';
			if (language === 'ja') text = '電話';
			if (language === 'en') text = 'Phone';
			break;

		case 'submit':
			if (language === 'zh') text = '提交';
			if (language === 'ja') text = '送信';
			if (language === 'en') text = 'Submit';
			break;
		case 'name':
			if (language === 'zh') text = '姓名';
			if (language === 'ja') text = '名前';
			if (language === 'en') text = 'Name';
			break;
		case 'contactWay':
			if (language === 'zh') text = '联系方式';
			if (language === 'ja') text = '連絡先';
			if (language === 'en') text = 'Contact';
			break;
		case 'content':
			if (language === 'zh') text = '内容';
			if (language === 'ja') text = '内容';
			if (language === 'en') text = 'Content';
			break;
		case 'contactSuccess':
			if (language === 'zh') text = '提交成功，我们会尽快与您联系';
			if (language === 'ja') text = '送信成功、できるだけ早くご連絡いたします';
			if (language === 'en')
				text =
					'Submitted successfully, we will contact you as soon as possible';
			break;
		case 'requireMessage':
			if (language === 'zh') text = '请务必填写完内容';
			if (language === 'ja') text = '内容を記入してください';
			if (language === 'en') text = 'Please fill in the content';
			break;
			case 'aboutUs':
				if (language === 'zh') text = '关于我们';
				if (language === 'ja') text = 'チーム紹介';
				if (language === 'en') text = 'About Us';
				break;

		case 'clickLinkJsx':
			if (language === 'zh')
				text = (
					<>
						点击对应卡片<span>跳转</span>
					</>
				);
			if (language === 'ja')
				text = (
					<>
						該当カードをクリックして<span>ジャンプ</span>
					</>
				);
			if (language === 'en')
				text = (
					<>
						Click the card to<span>jump</span>
					</>
				);
			break;
		default:
			break;
	}

	return {
		language,
		text,
	};
}

export function getLanguage() {
	return {
		language: language,
		icon: language === 'en' ? enIcon : language === 'ja' ? jaIcon : zhIcon,
	};
}

export const languageIcon = {
	zh: zhIcon,
	ja: jaIcon,
	en: enIcon,
};

export function setLanguage(lang) {
	localStorage.setItem('language', lang);
	window.location.href = '/';
}
