import Footer from '../../components/Footer/Footer';
import Head from '../../components/Head/Head';
import { getLanguage, getText } from '../../config/language';

import noisea_bg from '../../static/img/bg/noise_bg.png';
import main_shape from '../../static/img/images/main_shape.png';
import { useState } from 'react';
import config from '../../config/config';
import { message } from 'antd';

export default function () {
	const [contact, setContact] = useState({});

	function submit() {
		if (!contact.name || !contact.content || !contact.contact) {
			message.error(getText('requireMessage').text);
			return;
		}
		fetch(`${config.baseUrl}/attributes/contact`, {
			method: 'POST',
			mode: 'cors',
			body: JSON.stringify({
				name: contact.name,
				content: contact.content,
				contact: contact.contact,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((data) => {
			message.info(getText('contactSuccess').text);
			setContact((prev) => ({}));
		});
	}

	return (
		<>
			<Head />
			<main className="main-content">
				<div
					className="noise-bg"
					data-background={noisea_bg}
					style={{
						backgroundImage: `url(${noisea_bg})`,
					}}
				></div>
				<div
					className="main-shape"
					data-background={main_shape}
					style={{
						backgroundImage: `url(${main_shape})`,
					}}
				></div>
				{/* <section className="breadcrumb-area">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<div className="breadcrumb-content">
									<h2 className="title">{getText('getInTouchJsx').text}</h2>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				<section className="contact-area pb-140">
					<div className="container">
						{/* <div className="contact-info-wrap">
							<div className="row justify-content-center">
								<div className="col-lg-4 col-md-6">
									<div className="contact-info-item">
										<div className="icon">
											<i className="fas fa-map-marker-alt"></i>
										</div>
										<div className="content">
											<h2 className="title">{getText('address').text}</h2>
											<p>
												Address <br /> 10002,address
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6">
									<div className="contact-info-item">
										<div className="icon">
											<i className="fas fa-phone-volume"></i>
										</div>
										<div className="content">
											<h2 className="title">{getText('phone').text}</h2>
											<span>+86 111-111-1111</span>
											<span>+86 111-111-1111</span>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6">
									<div className="contact-info-item">
										<div className="icon">
											<i className="fas fa-envelope"></i>
										</div>
										<div className="content">
											<h2 className="title">{getText('email').text}</h2>
											<span>xxx1@xxx.com</span>
										
										</div>
									</div>
								</div>
							</div>
						</div> */}



						<div className="row">
							<div className="col-lg-12 breadcrumb-area" >
								<div className="contact-form-wrap">
									<h2 style={{marginBottom:"40px"}} className="title">{getText('contactUsJsx').text}</h2>
									<div style={{textAlign:"center",marginBottom:"60px"}}><a href='mailto:info@hotluuu.io' style={{color:"white"}}>mail to : <span style={{textDecoration:"underline"}}>info@houtluuu.io</span></a></div>
									
									<div className="row" style={{ justifyContent: 'center' }}>
										<div className="col-lg-8">
											<div className="contact-form">
												<form action="#">
													<div className="form-grp">
														<input
															type="text"
															id="name"
															placeholder={getText('name').text}
															value={contact.name || ''}
															onChange={(v) =>
																setContact((prev) => ({
																	...prev,
																	name: v.target.value,
																}))
															}
														/>
													</div>
													<div className="form-grp">
														<input
															type="text"
															id="conta ct"
															placeholder={getText('contactWay').text}
															value={contact.contact || ''}
															onChange={(v) =>
																setContact((prev) => ({
																	...prev,
																	contact: v.target.value,
																}))
															}
														/>
													</div>
													<div className="form-grp">
														<textarea
															name="content"
															id="content"
															placeholder={getText('content').text}
															value={contact.content || ''}
															onChange={(v) =>
																setContact((prev) => ({
																	...prev,
																	content: v.target.value,
																}))
															}
														></textarea>
													</div>
													<button
														onClick={(e) => {
															e.preventDefault();
															submit();
														}}
														className="btn"
														type="submit"
													>
														{getText('submit').text}
													</button>
												</form>
											</div>
										</div>
									</div>
									<div className="contact-shape">
										<img src="assets/img/images/contact_shape.png" alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<Footer />
		</>
	);
}
