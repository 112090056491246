import Footer from '../../components/Footer/Footer';
import Head from '../../components/Head/Head';
import { getLanguage, getText } from '../../config/language';

import noisea_bg from '../../static/img/bg/noise_bg.png';
import main_shape from '../../static/img/images/main_shape.png';
import { useState } from 'react';
import config from '../../config/config';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';
import lunbo1 from '../../static/img/tiaozhuan/tiaozhuan-1.jpg';
import lunbo2 from '../../static/img/tiaozhuan/tiaozhuan-2.jpg';
import lunbo3 from '../../static/img/tiaozhuan/tiaozhuan-3.jpg';
import lunbo4 from '../../static/img/tiaozhuan/tiaozhuan-4.jpg';
import lunbo5 from '../../static/img/tiaozhuan/tiaozhuan-5.jpg';
import lunbo6 from '../../static/img/tiaozhuan/tiaozhuan-6.jpg';
import "./market.scss"

export default function () {
	const [contact, setContact] = useState({});

	const [contentStyle, useContentStyle] = useState({
		width: '70%',
		margin: 'auto',
		color: '#fff',
		lineHeight: '160px',
		textAlign: 'center',
		background: '#364d79',
		borderRadius: '10px',
	});

	function go(url) {
		window.location.href = url;
	}

	return (
		<>
			<Head />
			<main className="main-content">




               

            <div
					className="noise-bg"
					data-background={noisea_bg}
					style={{
						backgroundImage: `url(${noisea_bg})`,
					}}
				></div>
				<div
					className="main-shape"
					data-background={main_shape}
					style={{
						backgroundImage: `url(${main_shape})`,
					}}
				></div>




            <section className="breadcrumb-area">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<div className="breadcrumb-content">
									<h2 className="title">{getText('clickLinkJsx').text}</h2>
								</div>
							</div>
						</div>
                        
					</div>
				</section>
                <div className={'market-main'}>
					<div
						onClick={() => go('/sensitiveDescription')}
						className={'market-main-l'}
					>
						<Carousel autoplay>
							<div>
								<img src={lunbo1} style={contentStyle} />
							</div>
							<div>
								<img src={lunbo2} style={contentStyle} />
							</div>
							<div>
								<img src={lunbo3} style={contentStyle} />
							</div>
						</Carousel>
						<div className={'market-text-l'}>
							<LeftOutlined style={{ color: '#f7f06d' }} />
							<LeftOutlined style={{ color: '#f7f06d' }} />
							HOTLuuu-idol Market
						</div>
					</div>
					<div
						onClick={() => go('https://hotluuu.io/market')}
						className={'market-main-r'}
					>
						<Carousel autoplay>
							<div>
								<img src={lunbo4} style={contentStyle} />
							</div>
							<div>
								<img src={lunbo5} style={contentStyle} />
							</div>
							<div>
								<img src={lunbo6} style={contentStyle} />
							</div>
						</Carousel>
						<div className={'market-text-r'}>
							HOTLuuu Market
							<RightOutlined style={{ color: '#74ebec' }} />
							<RightOutlined style={{ color: '#74ebec' }} />
						</div>
					</div>
				</div>





			

				
			</main>
			<Footer />
		</>
	);
}
