import Head from '../../components/Head/Head';
import Footer from '../../components/Footer/Footer';
import noisea_bg from '../../static/img/bg/noise_bg.png';
import { getLanguage, getText } from '../../config/language';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import config from '../../config/config';
import './News.scss';

export default function () {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const [newsList, setNewsList] = useState([]);

	useEffect(() => {
		fetch(
			`${config.baseUrl}/attributes/getNews/?language=${
				getLanguage().language
			}&pageSize=5&pageNum=${pageInfo.pageNo}`,
			{ mode: 'cors' }
		)
			.then((res) => res.json())
			.then((res) => {
				setNewsList(res?.list);
				setPageInfo((prev) => ({ ...prev, total: res?.count }));
			});
	}, []);

	let [pageInfo, setPageInfo] = useState({
		pageNo: parseInt(queryParams.get('pageNo')) || 1,
		pageSize: 5,
		total: 0,
	});

	let totalPages = Math.ceil(pageInfo.total / pageInfo.pageSize);

	let startPage = pageInfo.pageNo - 2;
	let endPage = pageInfo.pageNo + 2;

	if (startPage < 1) {
		startPage = 1;
		endPage = Math.min(5, totalPages);
	}

	if (endPage > totalPages) {
		endPage = totalPages;
		startPage = Math.max(1, endPage - 5);
	}

	return (
		<>
			<Head current={"news"} />

			<main className="main-content fix news">
				<div
					className="noise-bg"
					data-background={noisea_bg}
					style={{
						backgroundImage: `url(${noisea_bg})`,
					}}
				></div>
				<section className="banner-area">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="banner-content ta-animated-headline">
									<h2
										className="title ah-headline wow fadeInUp"
										data-wow-delay=".2s"
									>
										{getText('news').text}
									</h2>
									<h2
										className="title d-none wow fadeInUp"
										data-wow-delay=".2s"
									>
										{getText('news').text}
									</h2>
								</div>
							</div>
						</div>
						<div className={'news-box'}>
							{newsList
								.slice(
									(pageInfo.pageNo - 1) * pageInfo.pageSize,
									(pageInfo.pageNo - 1) * pageInfo.pageSize + pageInfo.pageSize
								)
								.map((item, index) => {
									return (
										<div
											onClick={() =>
												(window.location.href = `/newsDetail?id=${item.id}`)
											}
											key={item.id}
											className={'news-item'}
										>
											<div className={'time-panel'}>
												{dayjs(item.date).format('YYYY.MM.DD')}
											</div>
											<h1>{item.title}</h1>
										</div>
									);
								})}
						</div>

						<div
							className={'pagination'}
							style={{ display: pageInfo.total <= 5 ? 'none' : 'block' }}
						>
							<ul>
								{pageInfo.pageNo > 1 ? (
									<li>
										<a href={`/news?pageNo=${pageInfo.pageNo - 1}`}>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="currentColor"
												className="bi bi-caret-left-fill"
												viewBox="0 0 16 16"
											>
												<path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
											</svg>
										</a>
									</li>
								) : (
									''
								)}
								{[...Array(endPage - startPage + 1)]
									.map((_, index) => startPage + index)
									.map((item, index) => {
										return (
											<li
												key={item}
												className={`${
													item === pageInfo.pageNo ? 'active' : ''
												}`}
											>
												<a href={`/news?pageNo=${item}`}>{item}</a>
											</li>
										);
									})}

								<li>
									{pageInfo.pageNo <
									Math.ceil(pageInfo.total / pageInfo.pageSize) ? (
										<li>
											<a href={`/news?pageNo=${pageInfo.pageNo + 1}`}>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													fill="currentColor"
													className="bi bi-caret-right-fill"
													viewBox="0 0 16 16"
												>
													<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
												</svg>
											</a>
										</li>
									) : (
										''
									)}
								</li>
							</ul>
						</div>
					</div>
				</section>
			</main>

			<Footer />
		</>
	);
}
