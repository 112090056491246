import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import jaJp from 'antd/locale/ja_JP';
import zhCN from 'antd/locale/zh_CN';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import App2 from './App2';
import './index.scss';
import Page404 from './page/404/404';
import Contact from './page/Contact/Contact';
import Market from './page/Market/Market';
import NewsDetail from './page/News/Detail';
import News from './page/News/News';
import SensitiveDescriptiopn from './page/Sensitive/Sensitive';
import WhitePaper from './page/WhitePaper/WhitePaper';
import AboutUs from './page/AboutUs/AboutUs';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		errorElement: <Page404 />,
	},
	{
		path: '/2',
		element: <App2 />,
		errorElement: <Page404 />,
	},
	{
		path: '/news',
		element: <News />,
	},
	{ path: 'newsDetail', element: <NewsDetail /> },
	{
		path: '/contact',
		element: <Contact />,
	},
	{
		path: '/market',
		element: <Market />,
	},
	{
		path: '/sensitiveDescription',
		element: <SensitiveDescriptiopn />,
	},
	{
		path: '/whitepaper',
		element: <WhitePaper />,
	},
	{
		path: '/aboutUs',
		element: <AboutUs />,
	},
]);

const language = localStorage.getItem('language') || 'en';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ConfigProvider
		theme={'dark'}
		locale={language === 'zh' ? zhCN : language === 'ja' ? jaJp : enUS}
	>
		<React.StrictMode>
			<RouterProvider language={language} router={router} />
		</React.StrictMode>
	</ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
