import Footer from '../../components/Footer/Footer';
import Head from '../../components/Head/Head';
import { getLanguage, getText } from '../../config/language';

import noisea_bg from '../../static/img/bg/noise_bg.png';
import main_shape from '../../static/img/images/main_shape.png';
import { useState } from 'react';
import config from '../../config/config';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export default function () {
	function getValueForLanguage() {
		let language = localStorage.getItem('language');

		if (language === 'zh')
			return {
				title: '年龄认证',
				question: '您是满十八岁吗？',
				description: '这里是展示适合成人商品的网站，拒绝未满18岁的用户访问。',
				options: ['不是', '是'],
			};

		if (language === 'ja')
			return {
				title: '年齢認証',
				question: 'あなたは18歳以上ですか？',
				description:
					'ここから先は、アダルト商品を扱うアダルトサイトとなります。',
				options: ['いいえ', 'はい'],
			};

		return {
			title: ' Age Verification',
			question: 'Are you over 18 years old?',
			description:
				' This is a website that showcases products suitable for adults, and access is denied to users under the age of 18.',
			options: ['No', 'Yes'],
		};
	}

	return (
		<>
			<Head />
			<main className="main-content">
				<section className="banner-area">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="banner-content ta-animated-headline">
									<h2
										className="title ah-headline wow fadeInUp"
										data-wow-delay=".2s"
									>
										{getValueForLanguage().title}
									</h2>
									<div style={{ textAlign: 'center' }}>
										<h3>{getValueForLanguage().question}</h3>

										<p style={{ marginTop: '20px' }}>
											{getValueForLanguage().description}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div
							style={{
								textAlign: 'center',
								height: '100px',
								gap: '20px',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Button onClick={() => window.history.go(-1)}>
								A.{getValueForLanguage().options[0]}
							</Button>
							<Button
								onClick={() =>
									(window.location.href = 'https://hotluuu-idol.io/market')
								}
							>
								B.{getValueForLanguage().options[1]}
							</Button>
						</div>
					</div>
				</section>
			</main>
			<Footer />
		</>
	);
}
