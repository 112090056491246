import Head from '../../components/Head/Head';
import Footer from '../../components/Footer/Footer';
import noisea_bg from '../../static/img/bg/noise_bg.png';
import { getText } from '../../config/language';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import config from '../../config/config';
import dayjs from "dayjs"

export default function () {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const [item, setItem] = useState({});

	useEffect(() => {
		fetch(
			`${config.baseUrl}/attributes/getNewsDetail/?id=${queryParams.get('id')}`,
			{
				mode: 'cors',
			}
		)
			.then((res) => res.json())
			.then((res) => {
				setItem(res);
			});
	}, []);

	return (
		<>
			<Head current={'news'} />

			<main className="main-content fix">
				<div
					className="noise-bg"
					data-background={noisea_bg}
					style={{
						backgroundImage: `url(${noisea_bg})`,
					}}
				></div>
				<section className="banner-area">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="banner-content ta-animated-headline">
									<h2
										className="title ah-headline wow fadeInUp"
										data-wow-delay=".2s"
									>
									{item?.title || 'loading...'}
									</h2>
									<h2
										className="title d-none wow fadeInUp"
										data-wow-delay=".2s"
									>
										{item?.title || 'loading...'}
									</h2>
								</div>
								<div style={{ maxWidth: '1320px' }}>
								
									<div style={{ margin: '20px 0', textAlign: 'center' }}>
										{dayjs(item.date).format('YYYY-MM-DD HH:mm:ss')}
									</div>

									<div
										className={'detail-div'}
										style={{
											color: '#fff',
											lineHeight: '30px',
											paddingBottom: '20px',
										}}
										dangerouslySetInnerHTML={{ __html: item?.content }}
									></div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>

			<Footer />
		</>
	);
}
