import Footer from '../../components/Footer/Footer';
import Head from '../../components/Head/Head';

export default function () {
	return (
		<>
			<Head />
			<main class="main-content">
				<section class="error-area">
					<div
						class="error-bg"
						data-background="assets/img/bg/error_bg.jpg"
					></div>
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<div class="error-content text-center">
									<h2 class="title">Error Page</h2>
									<div class="error-img">
										<img src="assets/img/images/error_img.png" alt="" />
									</div>
									<span>Sorry we can't find that page!</span>
									<a href="/" class="btn">
										back to home
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<Footer />
		</>
	);
}
