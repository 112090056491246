import { useEffect, useState } from 'react';
import './App.css';
import Head from './components/Head/Head';
import Footer from './components/Footer/Footer';
import noisea_bg from './static/img/bg/noise_bg.png';
import main_shape from './static/img/images/main_shape.png';
import { getLanguage, getText } from './config/language';
import config from './config/config';
import dayjs from 'dayjs';
import introduceVideo from './static/videos/intro_video.mp4';
import { LoadingOutlined } from '@ant-design/icons';
import logo_colorful from './static/img/logo/hotluuu_colorful.png';
import logo_white from './static/img/logo/hotluuu_white.png';

function App() {
	const [loading, setLoading] = useState(true);
	const [newsList, setNewsList] = useState([]);
	const [projectIntroduceUrl, setProjectIntroduceUrl] = useState('');
	const [friendsImgs, setFriendsImgs] = useState([]);
	const [mainIntroduce, setMainIntroduce] = useState([]);

	useEffect(() => {
		Promise.all([
			// fetch(`${config.baseUrl}/attributes/?key=exchangeImgs&language=global`, {mode: 'cors'})
			//     .then(res => res?.json() || null)
			//     .then(res => {
			//         if (res)
			//             setExchangeLogoList(JSON.parse(res?.value || []));
			//     }),
			fetch(
				`${config.baseUrl}/attributes/?key=mainIntroduce&language=${
					getLanguage().language
				}`,
				{ mode: 'cors' }
			)
				.then((res) => res?.json() || null)
				.then((res) => {
					setMainIntroduce(res?.value);
				}),
			fetch(`${config.baseUrl}/attributes/?key=friendsImgs&language=global`, {
				mode: 'cors',
			})
				.then((res) => res?.json() || null)
				.then((res) => {
					if (res) setFriendsImgs(JSON.parse(res?.value || []));
				}),
			fetch(
				`${config.baseUrl}/attributes/?key=projectIntroduceUrl&language=${
					getLanguage().language
				}`,
				{ mode: 'cors' }
			)
				.then((res) => res?.json() || null)
				.then((res) => {
					setProjectIntroduceUrl(res?.value);
				}),
			fetch(
				`${config.baseUrl}/attributes/getNews/?language=${
					getLanguage().language
				}&pageSize=6&pageNum=1`,
				{ mode: 'cors' }
			)
				.then((res) => res?.json() || null)
				.then((res) => {
					setNewsList(res?.list);
				}),
		]).then(() => {
			setLoading(false);
		});
	}, []);

	if (loading) {
		return (
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					zIndex: 9999,
					backgroundColor: `var(--tg-black-two)`,
					display: 'flex',
					alignContent: 'center',
					justifyContent: 'center',
				}}
			>
				<div
					className="noise-bg"
					data-background={noisea_bg}
					style={{
						backgroundImage: `url(${noisea_bg})`,
					}}
				></div>
				<div
					className="main-shape"
					data-background={main_shape}
					style={{
						backgroundImage: `url(${main_shape})`,
					}}
				></div>
				<LoadingOutlined
					style={{
						fontSize: '80px',
					}}
				/>
			</div>
		);
	}

	return (
		<>
			<Head current={'/'} loading={loading} />
			<main className="main-content fix">
				<div
					className="noise-bg"
					data-background={noisea_bg}
					style={{
						backgroundImage: `url(${noisea_bg})`,
					}}
				></div>
				<div
					className="main-shape"
					data-background={main_shape}
					style={{
						backgroundImage: `url(${main_shape})`,
					}}
				></div>

				<section className="banner-area">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="banner-content ta-animated-headline">
									<h2
										className="title ah-headline wow fadeInUp"
										data-wow-delay=".2s"
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<img
											style={{ maxWidth: '580px', width: '1px',position:"relative",top:"10px", flexGrow: 1 }}
											src={logo_white}
											alt=""
										/>

										<div className='title ah-headline' style={{ width: '200px',margin:0 }}>
											<span className="ah-words-wrapper">
												<b className="is-visible">WebSite</b>
												<b>Official</b>
											</span>
										</div>
									</h2>

									<h2
										className="title d-none wow fadeInUp"
										data-wow-delay=".2s"
									>
										<span>Official</span>
									</h2>

									<p className="wow fadeInUp" data-wow-delay=".4s">
										{mainIntroduce}
									</p>
									<div className="banner-btn">
										<a
											href={null}
											onClick={() => {
												if (projectIntroduceUrl) {
													window.open(projectIntroduceUrl);
												} else {
													alert('请在后台设置跳转地址');
												}
											}}
											className="gradient-btn wow fadeInLeft"
											data-wow-delay=".6s"
										>
											{getText('projectIntroduce').text}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div className="video-area">
					<div className="video-shape">
						<svg
							height="1192"
							viewBox="0 0 1920 1192"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								stroke="url(#paint0_linear_2840_46)"
								strokeWidth="7"
								strokeDasharray="10 10"
								d="M-40.9996 902C-8.39405 961.001 87.0357 1262.13 234 1171.5C385.21 1078.25 424.961 618.039 479.564 680.288C534.166 742.538 625.164 842.979 735.172 706.451C845.181 569.923 839.697 412.37 1093.03 631.043C1346.36 849.717 1371.47 413.985 1477.97 274.534C1584.48 135.083 1738.61 381.41 1830.32 343.155C1922.04 304.9 1862.93 -74.0337 2236.96 18.2495"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_2840_46"
									x1="2117.79"
									y1="34.1404"
									x2="83.2194"
									y2="768.35"
									gradientUnits="userSpaceOnUse"
								>
									<stop offset="0" stopColor="rgba(200 189 255)" />
									<stop offset="0.13824" stopColor="#BAA6FF" />
									<stop offset="0.337481" stopColor="#6721FF" />
									<stop offset="0.900573" stopColor="#180048" />
									<stop offset="1" stopColor="#00CBFF" />
								</linearGradient>
							</defs>
						</svg>
					</div>
					<div className="container custom-container">
						<div className="row">
							<div className="col-lg-12">
								<div className="video-wrap">
									<video className="live-video" loop autoPlay muted>
										<source src={introduceVideo} type="video/mp4" />
										<source src="assets/videos/video_01.ogg" type="video/ogg" />
									</video>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <section className="counter-area pt-150 pb-90">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-4 col-md-6">
								<div className="counter-item">
									<h2 className="count">3DAYS</h2>
									<p>Average Saved / Week</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="counter-item">
									<h2 className="count">$400000</h2>
									<p>Trained AI Model</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="counter-item">
									<h2 className="count">480+</h2>
									<p>Powerful AI Business Tools</p>
								</div>
							</div>
						</div>
					</div>
				</section> */}

				<div style={{ marginTop: '200px' }}></div>

				<section className="writing-area pb-120">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-center mb-80">
									{/* <h2 className="title title-animation">Start writing <span>10x faster</span> with AI</h2> */}
									<h2 className="title title-animation">
										{getText('newsJsx').text}
									</h2>

									<div className="more-btn text-center mt-30">
										<a
											href="/news"
											className="gradient-btn gradient-btn-two btn-two"
										>
											{getText('more').text}
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="row justify-content-center">
							{newsList.map((item, index) => {
								return (
									<div
										key={item.id}
										className="col-xl-4 col-lg-6 col-md-6 col-sm-9"
									>
										<div className="writing-item">
											<div className="writing-shape">
												<svg
													viewBox="0 0 417 207"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
													x="0px"
													y="0px"
													preserveAspectRatio="none"
												>
													<g opacity="0.1">
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M0 96C0 82 7.5 73 26.2229 76.38C43.8225 79.5573 73.5 62.5 65 34C58.0931 10.8417 68.4854 0.0152226 90.4536 0H387C403.569 0 417 13.4315 417 30V177C417 193.569 403.569 207 387 207H30C13.4315 207 0 193.569 0 177V96Z"
															fill="currentcolor"
														/>
														<path
															d="M26.2229 76.38L26.0452 77.3641L26.0452 77.3641L26.2229 76.38ZM65 34L64.0417 34.2858L65 34ZM90.4536 0L90.4536 -1L90.4529 -1L90.4536 0ZM26.4005 75.3959C16.8849 73.6781 9.9765 75.0628 5.4433 78.9101C0.915622 82.7526 -1 88.8465 -1 96H1C1 89.1535 2.83438 83.7474 6.73743 80.4349C10.6349 77.1272 16.838 75.7019 26.0452 77.3641L26.4005 75.3959ZM64.0417 34.2858C68.1618 48.1001 63.0533 59.0984 54.7432 66.3139C46.3758 73.5791 34.8545 76.9221 26.4005 75.3959L26.0452 77.3641C35.1909 79.0152 47.3082 75.4182 56.0544 67.8241C64.858 60.1802 70.3382 48.3998 65.9583 33.7142L64.0417 34.2858ZM90.4529 -1C79.3517 -0.992307 70.8799 1.74143 66.1176 7.69682C61.3388 13.673 60.5475 22.57 64.0417 34.2858L65.9583 33.7142C62.5456 22.2717 63.4971 14.1764 67.6796 8.94589C71.8788 3.69466 79.5873 1.00753 90.4543 1L90.4529 -1ZM90.4536 1H387V-1H90.4536V1ZM387 1C403.016 1 416 13.9837 416 30H418C418 12.8792 404.121 -1 387 -1V1ZM416 30V177H418V30H416ZM416 177C416 193.016 403.016 206 387 206V208C404.121 208 418 194.121 418 177H416ZM387 206H30V208H387V206ZM30 206C13.9837 206 1 193.016 1 177H-1C-1 194.121 12.8792 208 30 208V206ZM1 177V96H-1V177H1Z"
															fill="currentcolor"
														/>
													</g>
												</svg>
											</div>
											<div className="writing-icon">
												<i className="fal fa-globe"></i>
											</div>
											<div className="writing-content">
												<h4 style={{ fontSize: '1rem' }}>
													{dayjs(item.created_at).format('YYYY-MM-DD')}
												</h4>
												<div
													style={{
														fontSize: '18px',
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{item.title}
												</div>

												<a
													style={{ fontSize: '1rem' }}
													href={`/newsDetail?id=${item.id}`}
													className="link-btn"
												>
													{getText('detail').text}
													<i className="far fa-arrow-right"></i>
												</a>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</section>
			</main>
			<Footer friendsImgs={friendsImgs} />
		</>
	);
}

export default App;
